.bloc-showreel {
  height: 100%;
  pointer-events: none;
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.6s $quad-ease-out;
  transition-delay: 1s;

  @include media($until: small) {
    opacity: 1;
  }

  &.visible {
    opacity: 1;
  }

  &-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

    @include media($until: small) {
      display: none;
    }
  }

  .video-container {
    width: 100%;
    position: absolute;
    top: 50%;
    flex: none;
    transform: translateY(-50%);

    .plyr {
      height: 100%;
      justify-content: center;
    }

    .plyr__video-wrapper {
      @include media($until: small) {
        margin-top: 0;
      }
    }
  }

  &-video-mobile {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: none;

    @include media($until: small) {
      display: block;
    }
  }
}