.bloc-awards {
  @include grid();
  padding-top: 200px;
  padding-bottom: 200px;
  width: 100%;
  opacity: 0;
  transition: opacity 0.6s $quad-ease-out;

  &.visible {
    opacity: 1;
  }

  @include media($until: small) {
    margin-top: 0;
    padding-top: 70px;
    padding-bottom: 70px;
    opacity: 1;
  }

  &__wrapper {
    @include grid-col(10);
    margin: 0 auto;

    @include media($until: small) {
      width: 100%;
      flex: none;
      margin: 0;
      max-width: none;
    }
    
    .title {
      font-size: 70px;
      opacity: 0.4;
      text-align: center;
      margin: 0 auto;
      margin-bottom: 100px;
      position: relative;

      @include media($until: small) {
        margin-bottom: 50px;
        font-size: 40px;
      }

      .title-palm-left,
      .title-palm-right {
        position: absolute;
        filter: invert(1);
        height: 130px;
        width: auto;
        top: 50%;
        transform: translateY(-50%)  translateX(-55px);

        @include media($until: small) {
          height: 70px;
          transform: translateY(-50%)  translateX(-38px);
        }
      }

      .title-palm-right {
        transform: scaleX(-1) translateY(-50%) translateX(15px);

        @include media($until: small) {
          transform: scaleX(-1) translateY(-50%);
        }
      }
    }

    .list {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      @include media($until: small) {
        flex-direction: column;
      }

      .item {
        width: 33%;
        padding-left: 30px;
        margin-top: 15px;
        text-align: center;
        margin-bottom: 25px;

        @include media($until: small) {
          width: 100%;
          margin: 0 auto;
          padding-left: 0;
          margin-bottom: 25px;
        }

        .place {
          font-size: 14px;
          text-transform: uppercase;
          font-weight: bold;
          margin-bottom: 7px;
        }

        .price,
        .date,
        .real {
          font-size: 14px;
          line-height: 1.7;
          display: inline-block;
        }

        .project {
          font-size: 14px;
          font-family: "Object Sans Slanted";
        }
      }
    }
  }
}