// Easings
$quad-ease-in: cubic-bezier(0.55, 0.085, 0.68, 0.53);
$cubic-ease-in: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$quart-ease-in: cubic-bezier(0.895, 0.03, 0.685, 0.22);
$quint-ease-in: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$sine-ease-in: cubic-bezier(0.47, 0, 0.745, 0.715);
$expo-ease-in: cubic-bezier(0.95, 0.05, 0.795, 0.035);
$circ-ease-in: cubic-bezier(0.6, 0.04, 0.98, 0.335);

$quad-ease-out: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$cubic-ease-out: cubic-bezier(0.215, 0.61, 0.355, 1);
$quart-ease-out: cubic-bezier(0.165, 0.84, 0.44, 1);
$quint-ease-out: cubic-bezier(0.23, 1, 0.32, 1);
$sine-ease-out: cubic-bezier(0.39, 0.575, 0.565, 1);
$expo-ease-out: cubic-bezier(0.19, 1, 0.22, 1);
$circ-ease-out: cubic-bezier(0.075, 0.82, 0.165, 1);

$quad-ease-in-out: cubic-bezier(0.455, 0.03, 0.515, 0.955);
$cubic-ease-in-out: cubic-bezier(0.645, 0.045, 0.355, 1);
$quart-ease-in-out: cubic-bezier(0.77, 0, 0.175, 1);
$quint-ease-in-out: cubic-bezier(0.86, 0, 0.07, 1);
$sine-ease-in-out: cubic-bezier(0.445, 0.05, 0.55, 0.95);
$expo-ease-in-out: cubic-bezier(1, 0, 0, 1);
$circ-ease-in-out: cubic-bezier(0.785, 0.135, 0.15, 0.86);

// Colors
$black: #000000;
$white: #fff;
$white-light: #D8D8D8;
$grey: #979797;


// Site
$default-grid-gutter: 15px;
$default-grid-columns: 12;
$default-grid-gutter-mobile: 15px;
$default-grid-max-width: 1440px;
$default-grid-padding: 40px;
$default-grid-padding-mobile: 20px;

// Breakpoints
$sq-breakpoints: (
  small-mobile: 350px,
  mobile: 480px,
  small: 800px,
  tablet: 1025px,
  wide: 1200px,
  large: 1400px,
);