.bloc-hero {
  width: 100%;
  height: 90vh;
  position: relative;
  opacity: 0;
  transition: opacity 0.6s $quad-ease-out;

  &.visible {
    opacity: 1;
  }

  @include media($until: small) {
    height: 40vh;
    opacity: 1;
  }

  &.has-video {
    .bloc-hero-poster {
      cursor: pointer;
    }
  }

  &.video-playing {
    .bloc-hero-play-btn {
      opacity: 0;
    }

    .bloc-hero-popin {
      opacity: 1;
      pointer-events: auto;
    }
  }

  &-poster {
    width: 100%;
    height: 100%;

    .image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-popin {
    pointer-events: none;
    opacity: 0;
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    background-color: $black;
    z-index: 10;
    transition: opacity 0.3s $quad-ease-out;

    .template-bpgproject & {
      background-color: $white;
    }

    .video,
    .video-container {
      width: 100%;
      height: calc(100% - 150px);
      position: relative;
      top: 75px;
      object-fit: cover;
      cursor: pointer;

      @include media($until: small) {
        height: auto;
        object-fit: contain;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .plyr {
      height: 100%;
    }
  }

  .play-btn {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    width: 47px;
    height: 47px;
    object-fit: contain;
    pointer-events: none;
    transition: opacity 0.3s $quad-ease-out;

    @include media($until: small) {
      width: 30px;
      height: 30px;
    }
  }

  .close {
    position: absolute;
    top: 20px;
    right: 10px;
    cursor: pointer;

    img {
      width: 30px;
      height: 30px;
      position: absolute;
      top: 0;

      .template-bpgproject & {
        filter: invert(1);
      }
    }
  }

  &-arrow {
    position: absolute;
    bottom: 50px;
    left: 50%;
    cursor: pointer;
    // z-index: 1;
    width: 22px;
    height: 22px;
    border-bottom: 2px solid $white;
    border-left: 2px solid $white;
    transform: translateX(-50%) rotate(-45deg);

    @include media($until: small) {
      // width: 18px;
      // height: 18px;
      // bottom: 30px;
      display: none;
    }
  }
}