.template-error {
  height: 100vh;
  overflow: hidden;

  h1 {
    font-size: 30px;
    margin: 0 auto;
    text-align: center;
    max-width: 500px;
    top: 50vh;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
  }
}