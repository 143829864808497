.template-home {
  height: 100vh;
  overflow: hidden;

  .main {
    height: 100vh;

    .app-container {
      height: 100vh;

      .main-menu {
        width: 100%;
        display: flex;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @include media($until: small) {
          flex-direction: column;
        }

        li {
          width: 50%;
          height: 100%;
          display: flex;
          justify-content: center;

          @include media($until: small) {
            width: 100%;
            height: 50%;
          }

          &:nth-of-type(2) {
            background-color: $white;

            h2 {
              color: $black;
            }
          }

          a {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            &:hover {
              img {
                transform: scale(1.01);
              }
            }

            h2 {
              font-family: "Object Sans Bold";
              margin-top: 20px;
              font-size: 14px;
              text-transform: uppercase;
            }
          }

          img {
            height: 100%;
            width: 80%;
            max-height: 400px;
            object-fit: contain;
            transition: transform 0.3s $quad-ease-out;

            @include media($until: small) {
              height: 50%;
            }
          }
        }
      }
    }
  }
}
