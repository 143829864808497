.bloc-projects-list {
  @include grid();
  width: 100%;

  .list {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    align-items: flex-start;

    .list-inner {
      width: 100%;
      position: relative;
      // overflow: hidden;

      .list-inner__list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        position: relative;
        width: 100%;
        align-items: flex-start;

        .list-inner__list-double-items {
          width: 34%;
          display: flex;
          flex-direction: column;

          @include media($until: small) {
            width: 100%;
          }
        }
      }
    }
  }

  .project-item {
    width: 100%;
    position: relative;
    overflow: hidden;
    opacity: 0;
    transition: opacity 0.6s $quad-ease-out;

    &.visible {
      opacity: 1;
    }

    @include media($until: small) {
      opacity: 1;
      width: 100% !important;
      position: relative !important;
      transform: none !important;
    }

    a {
      display: inline-block;
      width: 100%;
      height: 0;
      color: $white;
      padding-bottom: 47%;

      @include media($until: small) {
        padding-bottom: 57%;
      }

      .desktop &:hover {
        .projet-item-overlay {
          opacity: 0.4;
        }

        .project-item-img {
          transform: scale(1.03);
        }
      }

      .project-item-img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        left: 0;
        top: 0;
        transition: transform 6s $quad-ease-out;
      }

      .projet-item-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background: $black;
        opacity: 0.2;
        z-index: 1;
        transition: opacity 0.3s $quad-ease-out;
      }

      .project-item-content {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 2;
        padding: 40px;

        @include media($until: small) {
          padding: 15px;
        }

        .title {
          font-family: "Object Sans Bold";
          font-size: 30px;
          display: block;
          margin-bottom: 10px;
          max-width: 450px;

          @include media($until: small) {
            font-size: 14px !important;
            margin-bottom: 5px !important;
          }
        }

        .real {
          font-size: 24px;
          display: block;
          max-width: 440px;

          @include media($until: small) {
            font-size: 14px !important;
          }
        }
      }
    }
  }
}

.bloc-projects-list.layout--1 {
  .list {
    .project-item {
      &.index-0 {
        width: 66%;

        a {
          padding-bottom: 61%;
        }
      }
  
      &.index-1 {
        width: 100%;

        a {
          padding-bottom: 58.7%;

          .title {
            font-size: 24px;
          }

          .real {
            font-size: 20px;
          }
        }
      }
  
      &.index-2 {
        width: 100%;

        a {
          padding-bottom: 58.7%;

          .title {
            font-size: 24px;
          }

          .real {
            font-size: 20px;
          }
        }
      }
  
      &.index-3 {
        width: 50%;

        a {
          padding-bottom: 61%;
        }
      }
  
      &.index-4 {
        width: 50%;

        a {
          padding-bottom: 61%;
        }
      }
    }
  }
}

.bloc-projects-list.layout--2 {
  .list {
    .project-item {
      &.index-0 {
        width: 100%;

        a {
          padding-bottom: 58.7%;

          .title {
            font-size: 24px;
          }

          .real {
            font-size: 20px;
          }
        }
      }

      &.index-1 {
        width: 100%;

        a {
          padding-bottom: 58.7%;

          .title {
            font-size: 24px;
          }

          .real {
            font-size: 20px;
          }
        }
      }

      &.index-2 {
        width: 66%;

        a {
          padding-bottom: 61%;
        }
      }
  
  
      &.index-3 {
        width: 50%;

        a {
          padding-bottom: 61%;
        }
      }
  
      &.index-4 {
        width: 50%;

        a {
          padding-bottom: 61%;
        }
      }
    }
  }
}

.bloc-projects-list.layout--3 {
  .list {
    .project-item {
      &.index-0 {
        width: 50%;

        a {
          padding-bottom: 61%;
        }
      }
  
      &.index-1 {
        width: 50%;

        a {
          padding-bottom: 61%;
        }
      }

      &.index-2 {
        width: 100%;

        a {
          padding-bottom: 58.7%;

          .title {
            font-size: 24px;
          }

          .real {
            font-size: 20px;
          }
        }
      }

      &.index-3 {
        width: 100%;

        a {
          padding-bottom: 58.7%;

          .title {
            font-size: 24px;
          }

          .real {
            font-size: 20px;
          }
        }
      }

      &.index-4 {
        width: 66%;

        a {
          padding-bottom: 61%;
        }
      }
    }
  }
}

.bloc-projects-list.layout--4 {
  .list {
    .project-item {
      &.index-0 {
        width: 50%;

        a {
          padding-bottom: 61%;
        }
      }
  
      &.index-1 {
        width: 50%;

        a {
          padding-bottom: 61%;
        }
      }

      &.index-2 {
        width: 66%;

        a {
          padding-bottom: 61%;
        }
      }
  
      &.index-3 {
        width: 100%;

        a {
          padding-bottom: 58.7%;

          .title {
            font-size: 24px;
          }

          .real {
            font-size: 20px;
          }
        }
      }
  
      &.index-4 {
        width: 100%;

        a {
          padding-bottom: 58.7%;

          .title {
            font-size: 24px;
          }

          .real {
            font-size: 20px;
          }
        }
      }
    }
  }
}
