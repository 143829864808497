.bloc-bottom-infos {
  @include grid();
  width: 100%;
  min-height: 100px;
  padding: 70px $default-grid-padding 25px;

  @include media($until: small) {
    padding: 30px $default-grid-padding-mobile 50px;
  }

  .list {
    width: 100%;
    display: flex;
    justify-content: space-between;

    @include media($until: small) {
      flex-wrap: wrap;
    }

    li {
      width: 100%;
      padding: 0 20px;
      text-align: center;
      font-size: 17px;
      line-height: 1.4;

      @include media($until: small) {
        text-align: center !important;
        margin-bottom: 30px;
      }

      &:first-of-type {
        text-align: left;
        padding-left: 0;

        @include media($until: small) {
          padding: 0 20px;
        }
      }

      &:last-of-type {
        text-align: right;
        padding-right: 0;

        @include media($until: small) {
          padding: 0 20px;
        }
      }
    }
  }
}