@font-face{
  font-family: "Object Sans Regular";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src:
    url("/assets/fonts/object-sans/object-sans-regular.otf") format("opentype");
}

@font-face{
  font-family: "Object Sans Bold";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src:
    url("/assets/fonts/object-sans/object-sans-bold.otf") format("opentype");
}


@font-face{
  font-family: "Object Sans Slanted";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src:
    url("/assets/fonts/object-sans/object-sans-slanted.otf") format("opentype");
}


@font-face{
  font-family: "Object Sans Slanted Bold";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src:
    url("/assets/fonts/object-sans/object-sans-slanted-bold.otf") format("opentype");
}