.bloc-double-text {
  @include grid();
  width: 100%;
  min-height: 100px;
  padding: 230px $default-grid-padding 60px;

  @include media($until: small) {
    padding: 50px $default-grid-padding-mobile 30px;
  }

  &__wrapper {
    @include grid-col(10);
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    @include media($until: small) {
      width: 100%;
      margin: 0;
      flex: none;
      max-width: none;
    }
    
    .top-level {
      width: 100%;

      .text-wrapper {
        width: 50%;

        @include media($until: small) {
          width: 100%;
        }

        .text-big {
          font-family: "Object Sans Regular", sans-serif;
          width: 100%;
          font-size: 38px;
          text-align: right;
          line-height: 1.05;
          padding-right: 7px;

          @include media($until: small) {
            font-size: 30px;
            text-align: left;
          }
        }
      }
    }

    .bottom-level {
      margin-top: -120px;
      width: 100%;
      display: flex;
      justify-content: flex-end;

      @include media($until: small) {
        width: 100%;
        margin-top: 30px;
      }

      .text-wrapper {
        width: 50%;

        @include media($until: small) {
          width: 100%;
        }

        .text-small {
          font-family: "Object Sans Regular", sans-serif;
          width: 80%;
          font-size: 21px;
          line-height: 1.3;
          padding-left: 7px;

          @include media($until: small) {
            font-size: 20px;
            width: 100%;
          }
        }
      }
    }
  }
}