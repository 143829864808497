.bloc-more-infos {
  @include grid();
  width: 100%;
  min-height: 100px;
  padding: 70px $default-grid-padding 100px;

  @include media($until: small) {
    padding: 30px $default-grid-padding-mobile 50px;
  }

  &__wrapper {
    @include grid-col(10);
    margin: 0 auto;
    display: flex;
    justify-content: center;
    width: 100%;

    @include media($until: small) {
      width: 100%;
      max-width: none;
      margin: 0;
      flex: none;
    }

    .text-big {
      font-size: 50px;
      opacity: 0.4;
      line-height: 1;
      text-align: center;

      @include media($until: small) {
        font-size: 30px;
      }
    }
  }
}