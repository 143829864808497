@mixin grid($gutter: $default-grid-gutter, $max-width: $default-grid-max-width) {
  display: flex;
  flex-wrap: wrap;
  max-width: $max-width;
  margin: 0 auto;
}

@mixin grid-col($size, $columns: $default-grid-columns, $gutter: $default-grid-gutter, $offset: 0, $extended: false, $mobile_extended: false) {
  @if (unitless($gutter)) {
    $gutter: $gutter * 1px;
  }

  @if ($extended) {
    $num: calc(#{percentage($offset / $columns)});

    flex: 0 0 calc(#{percentage($size / $columns)});
    max-width: calc(#{percentage($size / $columns)});
    margin-right: 0;
    margin-left: if($offset == 0, 0, $num);
  }

  @else {
    $num: calc(#{percentage($offset / $columns)} + #{$gutter / 2});

    flex: 0 0 calc(#{percentage($size / $columns)} - #{$gutter});
    max-width: calc(#{percentage($size / $columns)} - #{$gutter});
    margin-right: $gutter / 2;
    margin-left: if($offset == 0, $gutter / 2, $num);
  }
}
