* {
  font-weight: 400;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body,
html {
  font-family: "Object Sans Regular", sans-serif;
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 100%;
  background: $black;
  color: $white;
  transition: background 0.3s $quad-ease-out;

  &.no-scroll {
    overflow: hidden;
  }

  &.template-bpgprojects,
  &.template-bpgproject,
  &.template-bpgcontact {
    background: $white;
    color: $black;
  }
}

.app-container {
  transition: opacity 0.3s $quad-ease-out;
  min-height: 100vh;
  padding-bottom: 100px;

  @include media($until: small) {
    padding-bottom: 70px;
  }

  .template-home & {
    padding: 0;

    @include media($until: small) {
      padding-bottom: 0;
    }
  }
}

.app-wrapper {
  min-height: 100vh;
  position: relative;
  z-index: 2;
  background-color: $black;
  transition: background 0.3s $quad-ease-out;

  .template-bpgprojects &,
  .template-bpgproject &,
  .template-bpgcontact & {
    background: $white;
  }
}

button,
input,
textarea {
  outline: none;
  border: none;
  background: none;
}

a {
  color: inherit;
  text-decoration: none;
  outline: none;
}

li {
  list-style: none;
}

picture img,
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

img {
  width: 300px;
}

input,
textarea {
  color: white;
}

.main-page-title {
  opacity: 0;
  pointer-events: none;
  position: absolute;
}