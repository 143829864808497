.app-header {
  @include grid();
  width: 100vw;
  min-height: 100px;
  display: flex;
  align-items: center;
  padding: 30px $default-grid-padding;
  padding-top: 30px;
  position: fixed;
  padding-bottom: 30px;
  justify-content: space-between;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;

  @include media($until: small) {
    padding: 20px;
    position: sticky;
    left: 0;
    transform: none;
  }

  &.visible {
    .main-menu,
    .site-section-menu,
    .logo-link {
      opacity: 1 !important;
    }
  }

  &.menu-opened {
    .menu-container {
      opacity: 1;
      pointer-events: auto;
    }

    .menu-burger {
      .n-2 {
        opacity: 0;
      }

      .n-1 {
        width: 40px;
        transform: translateY(9px) rotate(45deg);
      }

      .n-3 {
        width: 40px;
        transform: translateY(-8px) rotate(-45deg);

        .safari & {
          transform: translateY(-7px) rotate(-45deg);
        }
      }
    }
  }

  &.app-header-common {
    .logo-link {
      opacity: 1 !important;
    }
  }

  &.has-real {
    .site-sections-links {
      .sections-links--links {
        opacity: 0;
        pointer-events: none;
      }

      .link-reset-grid {
        opacity: 1;
        pointer-events: auto;
      }
    }

    .menu-container {
      .site-section-menu {
        opacity: 0;
        pointer-events: none;
      }
    }

    &.real-desnt-exists {
      @include media($from: small) {
        filter: invert(1);
      }
    }
  }

  .template-bpgcontact & {
    .logo-link {
      border-color: $black !important;

      @include media($until: small) {
        border-color: $white !important;
      }

      img {
        filter: invert(1);

        @include media($until: small) {
          filter: none;
        }
      }
    }

    .menu-container {
      .link {
        color: $black;

        @include media($until: small) {
          color: $white;
        }

        &::before {
          background: $black;

          @include media($until: small) {
            background: $white;
          }
        }
      }
    }
  }

  .template-bpgproject & {
    filter: invert(0.12);

    @include media($until: small) {
      filter: none;
    }

    .real-text {
      display: none;
    }

    .sections-links--links {
      opacity: 1 !important;
      pointer-events: auto !important;
    }

    .link-reset-grid {
      display: none;
    }
  }

  .template-bpgcontact & {
    .real-text {
      display: none;
    }

    .sections-links--links {
      opacity: 1 !important;
      pointer-events: auto !important;
    }

    .link-reset-grid {
      display: none;
    }
  }

  .template-bpgproject &,
  .template-bpgprojects &,
  .template-bpgcontact & {
    @include media($until: small) {

      .site-sections-links,
      .menu-burger,
      .real-text {
        filter: invert(1);
      }

      &.menu-opened {
        .menu-burger {
          filter: none;
        }
      }
    }
  }

  .site-sections-links {
    display: flex;

    .sections-links--links {
      display: flex;
      transition: opacity 0.3s $quad-ease-out;
    }

    .logo-link {
      display: flex;
      width: 95px;
      height: 95px;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      border: 1px solid $white;
      padding: 25px;
      margin-right: 15px;
      transition: opacity 0.3s $quad-ease-out;

      @include media($until: small) {
        width: 45px;
        height: 45px;
        padding: 12px;
      }

      &:nth-of-type(2) {
        opacity: 0.5;
      }

      &:hover {
        opacity: 1;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .link-reset-grid {
      cursor: pointer;
      text-transform: lowercase;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      color: $white;
      font-size: 22px;
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.3s $quad-ease-out;

      @include media($until: small) {
        font-size: 16px;
      }
    }
  }

  .real-text {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-family: "Object Sans Bold";
    font-size: 35px;
    color: $white;
    text-align: center;

    @include media($until: small) {
      text-align: center;
      font-size: 18px;
      margin-top: 2px;
    }
  }

  .menu-container {
    display: flex;

    @include media($until: small) {
      opacity: 0;
      pointer-events: none;
      position: fixed;
      background-color: $black;
      width: 100vw;
      height: 100vh;
      left: 0;
      top: 0;
      display: flex;
      justify-content: flex-end;
      padding: 50px 30px;
      flex-direction: column;
      z-index: 10;
      transition: opacity 0.3s $quad-ease-out;
      padding-bottom: 130px;
    }
  }

  .main-menu,
  .site-section-menu {
    display: flex;
    transition: opacity 0.6s $quad-ease-out;

    @include media($until: small) {
      flex-direction: column;
      width: 100%;
      // opacity: 1 !important;
    }

    li {
      a {
        text-transform: lowercase;
        position: relative;
        color: $white;
        font-size: 22px;
        margin-left: 20px;

        @include media($until: small) {
          font-size: 35px;
          margin-left: 0;
        }

        &::before {
          content: '';
          transform: scaleX(0);
          width: 100%;
          position: absolute;
          left: 0;
          bottom: -2px;
          background: $white;
          height: 2px;
          transform-origin: left;
          transition: transform 0.3s $quad-ease-out;
        }

        &.link-section-asm,
        &.link-section-bpg {
          display: none;
        }
      }

      .desktop &:hover,
      .desktop &.active {
        a:before {
          transform: scaleX(1);
        }
      }

      &.hidden {
        display: none;
      }
    }
  }

  .header-text {
    display: none;
    width: 100%;
    margin-top: 20px;
    color: #fff;

    @include media($until: small) {
      display: block;
    }
  }

  .header-footer {
    display: flex;
    min-height: 40px;
    height: 70px;
    align-items: center;
    padding: 10px $default-grid-padding-mobile;
    justify-content: space-between;
    background-color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: none;

    @include media($until: small) {
      display: flex;
    }
  }

  .header-socials {
    display: flex;
    width: 33%;
    justify-content: center;

    @include media($until: small) {
      width: 50%;
    }

    li {
      margin: 0 10px;
      transition: opacity 0.3s $quad-ease-out;

      a {
        color: $black;
      }

      @include media($until: small) {
        margin: 0;
        font-size: 12px;
        margin-right: 5px;
      }

      &:hover {
        opacity: 0.4;
      }
    }
  }

  .header-credits {
    width: 33%;
    text-align: right;
    color: #929292;

    @include media($until: small) {
      font-size: 12px;
      width: 50%;
    }

    a {
      transition: opacity 0.3s $quad-ease-out;

      &:hover {
        opacity: 0.4;
      }
    }
  }

  .menu-burger {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    transition: background 0.3s $quad-ease-out;
    z-index: 21;
    cursor: pointer;

    @include media($until: small) {
      display: flex;
    }

    .n-1,
    .n-2,
    .n-3 {
      background: $white;
      height: 3px;
      width: 40px;
      border-radius: 20px 20px;
      transition: opacity 0.3s $quad-ease-out, transform 0.5s $quad-ease-out;
    }

    .n-2 {
      margin-top: 5px;
    }

    .n-3 {
      margin-top: 5px;
    }
  }

  .home-mobile-link-container {
    position: absolute;
    pointer-events: none;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100%;
    display: none;

    @include media($until: small) {
      .template-home & {
        display: flex;
      }
    }
  }

  .home-mobile-link {
    display: flex;
    position: absolute;
    bottom: 20px;
    left: 30px;
    color: $white;
    z-index: 1;
    pointer-events: auto;
    font-size: 16px;

    .arrow {
      bottom: 50px;
      left: 50%;
      margin-right: 10px;
      width: 10px;
      height: 10px;
      border-bottom: 2px solid $white;
      border-left: 2px solid $white;
      transform: translateX(-50%) rotate(-45deg);
    }
  }
}
