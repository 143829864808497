.app-footer {
  @include grid();
  width: 100vw;
  height: 100px;
  position: fixed;
  display: flex;
  z-index: 1;
  bottom: 0;
  align-items: flex-end;
  padding-top: 25px;
  padding-bottom: 25px;
  justify-content: space-between;
  z-index: 1;
  background: $white;
  color: $black;
  font-size: 15px;
  padding: 25px $default-grid-padding;
  left: 50%;
  transform: translateX(-50%);

  @include media($until: small) {
    min-height: 40px;
    height: 70px;
    align-items: center;
    padding: 10px $default-grid-padding-mobile;
  }

  &::before {
    content: '';
    width: 100vw;
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    background-color: $white;
    z-index: -1;
    transform: translateX(-50%);
  }
  
  .footer-text {
    width: 33%;

    @include media($until: small) {
      display: none;
    }

    a {
      text-decoration: underline;
      transition: opacity 0.3s $quad-ease-out;

      &:hover {
        opacity: 0.4;
      }
    }
  }

  .footer-socials {
    display: flex;
    width: 33%;
    justify-content: center;

    @include media($until: small) {
      width: 50%;
    }

    li {
      margin: 0 10px;
      transition: opacity 0.3s $quad-ease-out;

      @include media($until: small) {
        margin: 0;
        font-size: 12px;
        margin-right: 5px;
      }

      &:hover {
        opacity: 0.4;
      }
    }
  }

  .footer-credits {
    width: 33%;
    text-align: right;
    color: #929292;

    @include media($until: small) {
      font-size: 12px;
      width: 50%;
    }

    a {
      transition: opacity 0.3s $quad-ease-out;

      &:hover {
        opacity: 0.4;
      }
    }
  }
}