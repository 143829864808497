.bloc-intro {
  @include grid();
  margin-top: 60px;
  margin-bottom: 100px;
  opacity: 0;
  transition: opacity 0.6s $quad-ease-out;

  &.visible {
    opacity: 1;
  }

  @include media($until: small) {
    margin-top: 20px;
    margin-bottom: 50px;
    opacity: 1;
  }

  &__title-indicative {
    font-family: "Object Sans Bold";
    font-size: 16px;
    text-transform: uppercase;
    line-height: 1.5;
    margin-bottom: 25px;
  }

  &__wrapper {
    @include grid-col(8);
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;

    @include media($until: small) {
      @include grid-col(12);
      padding: 0 19px;
      margin: 0 auto;
    }

    .intro-top {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      .top-item {
        width: 33%;
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        padding-right: 25px;

        @include media($until: small) {
          width: 100%;
          padding-right: 0;
        }

        .item-title {
          font-family: "Object Sans Bold";
        }

        .item-title,
        .item-content {
          font-size: 14px;
          text-transform: uppercase;
          line-height: 1.5;
        }
      }
    }

    .intro-desc {
      width: 62%;
      margin-top: 70px;

      @include media($until: small) {
        margin-top: 50px;
        width: 100%;
      }

      .text {
        font-size: 24px;
        display: block;
        line-height: 1.4;

        strong,
        b {
          font-weight: bold;
        }

        a {
          border-bottom: 1px solid rgba($color: $white, $alpha: 0.4);
          transition: opacity 0.3s $quad-ease-out;

          &:hover {
            opacity: 0.7;
          }
        }

        @include media($until: small) {
          font-size: 13px;
        }
      }
    }

    .intro-awards {
      width: calc(100% + 30px);
      position: relative;
      left: -15px;
      display: flex;
      margin-top: 40px;
      justify-content: flex-end;
      align-items: flex-end;

      @include media($until: small) {
        width: 100%;
        left: 0;
        margin-top: 80px;
        justify-content: center;
      }

      .list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        @include media($until: small) {
          flex-direction: column;
        }

        .item {
          width: 25%;
          padding: 0 15px;
          margin-top: 35px;
          text-align: center;

          @include media($until: small) {
            width: 70%;
            margin: 0 auto;
            padding-left: 0;
            margin-bottom: 25px;
          }

          .place {
            font-size: 14px;
            text-transform: uppercase;
            font-weight: bold;
            margin-bottom: 7px;
            line-height: 1.6;
          }

          .price,
          .date {
            font-size: 14px;
            line-height: 1.4;
            display: inline;
          }
        }
      }
    }
  }
}
