.bloc-mesh {
  @include grid();
  margin-top: 110px;
  width: 100%;
  display: flex;
  position: relative;
  opacity: 0;
  transition: opacity 0.6s $quad-ease-out;

  &.visible {
    opacity: 1;
  }

  @include media($until: small) {
    flex-direction: column;
    margin-top: 50px;
    opacity: 1;
  }

  li {
    width: 50%;
    padding: 6px 0;
    padding-bottom: 0;
    position: relative;
    top: 4px;

    @include media($until: small) {
      width: 100%;
      padding: 0;
    }
  }

  a {
    display: inline-block;
    width: 100%;
    height: 0;
    padding-bottom: 56%;
    position: relative;
    overflow: hidden;

    .desktop &:hover {
      .item-overlay {
        opacity: 0.4;
      }

      .item-img {
        transform: scale(1.1);
      }
    }

    .item-img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      left: 0;
      top: 0;
      transition: transform 6s $quad-ease-out;
    }

    .item-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background: $black;
      opacity: 0;
      z-index: 1;
      transition: opacity 0.3s $quad-ease-out;
    }

    .item-content {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 2;
      padding: 40px;

      @include media($until: small) {
        padding: 15px;
      }

      .title {
        font-family: "Object Sans Bold";
        font-size: 30px;
        display: block;
        margin-bottom: 10px;
        max-width: 450px;

        @include media($until: small) {
          font-size: 14px;
          margin-bottom: 5px;
        }
      }

      .real {
        font-size: 24px;
        display: block;
        max-width: 440px;

        @include media($until: small) {
          font-size: 14px;
        }
      }

      .title {
        font-family: "Object Sans Bold";
        font-size: 30px;
        display: block;
        margin-bottom: 10px;
        max-width: 450px;

        .template-bpgproject & {
          color: $white;
        }

        @include media($until: small) {
          font-size: 14px;
          margin-bottom: 5px;
        }
      }

      .real {
        font-size: 24px;
        display: block;
        max-width: 440px;

        .template-bpgproject & {
          color: $white;
        }

        @include media($until: small) {
          font-size: 14px;
        }
      }
    }
  }
}