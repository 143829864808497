.bloc-banner-directors {
  width: 100vw;
  height: 100vh;
  background-color: $white;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(-100%);
  transition: transform 0.5s $quad-ease-out;

  &.visible {
    transform: none;
  }

  .close {
    position: absolute;
    top: 50px;
    left: 40px;
    cursor: pointer;
    z-index: 1;
    filter: invert(1);

    @include media($until: small) {
      top: 30px;
      left: 30px;
    }

    img {
      width: 30px;
      height: 30px;
      position: absolute;
      top: 0;
    }
  }

  &__bg-logo {
    width: 210px;
    height: 210px;
    opacity: 0.08;
    object-fit: contain;
  }

  &__names-container {
    @include grid();
    position: absolute;
    width: 100%;
    margin: 0 auto;
    max-height: 100vh;
    overflow: auto;
    padding-top: 100px;
    padding-bottom: 100px;

    ul {
      @include grid-col(8);
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin: 0 auto;

      li {
        font-family: "Object Sans Bold";
        width: 50%;
        font-size: 20px;
        margin: 15px 0;

        @include media($until: small) {
          width: 100%;
          text-align: center !important;
          font-size: 20px;
          margin: 10px 0;
        }

        &:nth-of-type(2n) {
          text-align: right;
        }

        span {
          cursor: pointer;
          position: relative;

          &::before {
            content: '';
            transform: scaleX(0);
            width: 100%;
            position: absolute;
            left: 0;
            bottom: -2px;
            background: $black;
            height: 3px;
            transform-origin: left;
            transition: transform 0.3s $quad-ease-out;
          }

          .desktop &:hover,
          .desktop &.active {
            &:before {
              transform: scaleX(1);
            }
          }
        }
      }
    }
  }
}
