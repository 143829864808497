.bloc-credits {
  @include grid();
  margin-top: 120px;
  margin-bottom: 170px;
  width: 100%;
  opacity: 0;
  transition: opacity 0.6s $quad-ease-out;

  &.visible {
    opacity: 1;
  }

  @include media($until: small) {
    margin-top: 50px;
    margin-bottom: 70px;
    opacity: 1;
  }

  &__wrapper {
    margin: 0 auto;

    .list {
      display: flex;
      flex-wrap: wrap;
      max-width: 500px;
      margin: 0 auto;

      @include media($until: small) {
        flex-direction: column;
      }

      .item {
        width: 50%;
        padding: 0 10px;
        margin-top: 15px;
        text-align: center !important;

        @include media($until: small) {
          width: 180px;
          padding-left: 0;
        }

        .title {
          font-family: "Object Sans Bold";
          font-size: 14px;
          margin-bottom: 2px;
          display: block;

          @include media($until: small) {
            font-size: 13px;
          }
        }
    
        .text {
          font-size: 14px;
          display: block;
          line-height: 1.4;

          @include media($until: small) {
            font-size: 13px;
          }
        }
      }
    }
  }
}